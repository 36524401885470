<template>
  <div>

    <v-container v-if="!loading">

      <v-card-title>

        <v-breadcrumbs class="text-uppercase font-weight-bolder" :items="items" />

        <v-spacer />

        <v-row>

          <v-col cols="12" class="text-right noprint">

            <span>Partilhar Link:</span>

            <share-it :text="

                    produto.designacao +

                    ' na Mutue Loja Online por apenas ' +

                    formatePrice(produto.pvp) +

                    ' AKZ'

                  " :url="

                    'https://loja.mutue.net/#/portal/pages/produto/detalhes/' +

                    produto.uuid

                  " icons outline :targets="['whatsapp', 'facebook']" />

          </v-col>

        </v-row>

      </v-card-title>

      <!-- 

      <div class="images" v-viewer>

              <img class="center" :src="SERVER + produto.imagem_produto" />

              <img

                style="cursor: pointer"

                width="60"

                v-for="(image, index) in produto.produto_imagens"

                :key="index"

              />

            </div>

     -->

      <v-row v-if="!loading">

        <v-col>

          <div class="images justify-center" v-viewer>

            <v-avatar class="justify-center" v-viewer rounded size="350">

              <img :src="SERVER + produto.imagem_produto" style="cursor: pointer" />

            </v-avatar>

            <br />

            <v-avatar class="mt-2 justify-center" v-viewer rounded size="90" v-for="(image, index) in produto.produto_imagens" :key="index">

              <v-row class="justify-center">

                <v-col cols="12">

                  <img class="images mr-2" style="cursor: pointer" :src="SERVER + image.url" />

                </v-col>

              </v-row>

            </v-avatar>

          </div>

          <!-- <VueSlickCarousel

                  ref="c1"

                  :fade="true"

                  :asNavFor="$refs.c2"

                  :focusOnSelect="true"

                >

                  <div>

                    <v-img

                      class="mx-auto"

                      width="300"

                      height="auto"

                      :src="produto.imagem_produto"

                    ></v-img>

                  </div>

                </VueSlickCarousel>

                <VueSlickCarousel

                  ref="c2"

                  class="product-slider-click"

                  :asNavFor="$refs.c1"

                  :slidesToShow="3"

                  :focusOnSelect="true"

                ><div

                    v-for="(item, index) in produto.produto_imagens"

                    :key="index"

                  >

                    <v-avatar class="white rounded mr-3" tile size="78">

                      <img :src="item.url" alt="" />

                    </v-avatar>

                  </div>

                </VueSlickCarousel> -->

          <!-- </div> -->

        </v-col>

        <!-- <v-col cols="12" lg="6" align="center">

                  <v-img max-width="300" src="@/assets/images/products/headphone.png"></v-img>

                  <div class="d-flex justify-center mt-12">

                      <v-avatar tile class="rounded-lg border-primary bg-white me-3" size="70" >

                          <img class="pa-3 " src="@/assets/images/products/headphone.png" alt="">

                      </v-avatar>

                      <v-avatar tile class="rounded-lg border-grey bg-white me-3" size="70" >

                          <img class="pa-3 " src="@/assets/images/products/headphone.png" alt="">

                      </v-avatar>

                      <v-avatar tile class="rounded-lg border-grey bg-white" size="70" >

                          <img class="pa-3 " src="@/assets/images/products/headphone.png" alt="">

                      </v-avatar>

                  </div>

                </v-col> -->

        <!-- <v-card elevation="0"> -->

        <v-col cols="12" class="mt-3" lg="6" style="background-color: white">

          <h1 hidden class="mb-4">{{ produto.uuid }}</h1>

          <h1 class="mb-4">{{ produto.designacao }}</h1>

          <h6 class="red--text">

            <!-- {{ produto.existencia_estock[0].quantidade }} -->

          </h6>

          <!-- <div class="d-flex mb-4">

              <p class="grey--text text--grey-lighten-1 me-3 mb-0">Categoria:</p>

              <p class="font-600 mb-0">{{ produto.categoria.designacao }}</p>

            </div> -->

          <!-- <div class="d-flex mb-4">

                <p class="grey--text text--grey-lighten-1 me-3 mb-0">Marca:</p>

                <p class="font-600 mb-0">Ziaomi</p>

              </div> -->

          <div class="d-flex mb-4">

            <p class="black--text text--grey-lighten-1 me-3 mb-0">

              Classificação:

            </p>

            <div class="d-flex align-center justify-center align-center mb-1">

              <v-rating :value="parseInt( produto.classificacao[0]?produto.classificacao[0].classificacao:5)" color="amber" dense half-increments readonly size="16"></v-rating>

              <div class="grey--text text--darken-4 ml-1">({{produto.classificacao[0]?produto.classificacao[0].classificacao:5 }})</div>

            </div>

          </div>

          <div class="mb-4">

            <h2 class="primary--text">{{ formatePrice(produto.pvp) }} AKZ</h2>

            <p class="text-left">IVA incluido</p>

            <!-- <h5> 
              {{ produto.existencia_estock }}
            </h5> -->

            <p class="green--text font-weight-bold" v-if="produto.existencia_estock[0].quantidade > 0">

              Em stock

            </p>

            <p class="red--text text-decoration-line-through font-weight-bold text-uppercase" v-else>

              stock esgotado

            </p>

          </div>

          <div class="mb-6">

            <!-- <h5>

                  {{ produto.existencia_estock[0].quantidade }} 

                  VS

                  {{ produto.quantidadeCarrinho }}

                </h5> -->

            <!-- <h2>{{ checkProdutoTemStock(produto) }}</h2> -->

            <v-btn @click="addProdutoCarrinho()" color="secondary" class="text-capitalize mb-3" :class="'d-block'" v-if="checkProdutoTemStock(produto)">

              <v-icon>mdi-cart-plus</v-icon> Adicionar ao carrinho

            </v-btn>

            <v-row>

              <v-col cols="10">

                <!-- <v-btn outlined title="Aumentar Quantidade ao carrinho" @click="incrementar(produto)">

                      <v-icon large color="success" class="font-weight-bold" btn>mdi-plus</v-icon>

                    </v-btn>

                    <v-btn outlined class="ml-2" title="Diminuir quantidade ao carrinho" @click="decrementar(produto)">

                      <v-icon large color="error" class="font-weight-bold" btn>mdi-minus</v-icon>

                    </v-btn> -->

                <v-row v-if="produto.existencia_estock[0].quantidade > 0">

                  <div class="mt-4 ml-4">

                    <v-btn outlined class="ml-2" title="Diminuir quantidade ao carrinho" :disabled="carrinho.qty == 1" @click="decrementar(produto)">

                      <v-icon large color="error" class="font-weight-bold" btn>mdi-minus</v-icon>

                    </v-btn>

                  </div>

                  <v-col cols="2">

                    <v-text-field name="name" dense id="id" v-model="carrinho.qty" @keyup.enter="checkQtyCart" outlined type="number" maxLenght="2" min="1"></v-text-field>

                  </v-col>

                  <div class="mt-4">

                    <v-btn outlined title="Aumentar Quantidade ao carrinho" :disabled="

                            produto.existencia_estock[0].quantidade == carrinho.qty

                          " @click="incrementar(produto)">

                      <v-icon large color="success" class="font-weight-bold" btn>mdi-plus</v-icon>

                    </v-btn>

                  </div>

                </v-row>

              </v-col>

            </v-row>

            <v-btn @click="addProdutoNosFavoritos()" color="secondary" class="text-capitalize mb-3" :class="'d-block'" text outlined>

              <v-icon>mdi-heart</v-icon>

              Adicionar nos Favoritos

            </v-btn>

            <!-- <div

                class="align-center"

                :class="singleProductData.qty <= 0 ? 'd-none' : 'd-flex'"

              >

                <v-btn

                  color="primary"

                  outlined

                  icon

                  tile

                  class="br-8"

                  @click="removeCartx(singleProductData)"

                >

                  <v-icon>mdi-minus</v-icon>

                </v-btn>

                <h3 class="mx-5">{{ singleProductData.qty }}</h3>

                <v-btn

                  color="primary"

                  outlined

                  icon

                  tile

                  class="br-8"

                  @click="addCartx(singleProductData)"

                >

                  <v-icon>mdi-plus</v-icon>

                </v-btn>

              </div> -->

          </div>

          <!-- <div class="d-flex mb-4">

                        <p class="black--text text--grey-lighten-1 me-3">Vendedor:</p>

                        <p class="font-600">{{ produto.empresa.nome }}</p>

                    </div> -->

          <!-- <h1>

             </h1> -->

        </v-col>

        <!-- </v-card> -->

        <v-col cols="12" class="mt-12">

          <v-tabs v-model="tab" v-if="produto.caracteristicas.length > 0" background-color="transparent" color="primary">

            <!-- <v-tab class="font-600 text-capitalize"> Detalhes </v-tab> -->

            <v-tab class="font-600 text-capitalize"> Descrição </v-tab>

            <!-- <v-tab class="font-600 text-capitalize"> Avaliações </v-tab> -->

          </v-tabs>

          <v-tabs-items class="transparent my-6 pb-6" v-model="tab">

            <!-- <v-tab-item>

                  <h5 class="mb-4">{{ produto.designacao }}</h5>

                  <span class="subtitle-1 grey--text">

                    Preço:

                    <span class="subtitle-1 black--text">

                      {{ formatePrice(produto.pvp) }}

                      KZ

                    </span>

                  </span>

                  <br />

                  <span class="subtitle-1 grey--text">

                    Categoria:

                    <span class="subtitle-1 black--text">

                      {{ produto.categoria.designacao }}

                    </span>

                  </span>

                  <br />

                </v-tab-item> -->

            <v-tab-item name="tab-Descricão">

              <v-simple-table>

                <tbody>

                  <tr v-for="descricao in produto.caracteristicas" :key="descricao.id">

                    <td class="text-uppercase font-weight-bold">

                      {{ descricao.designacao }}:

                    </td>

                    <td>

                      <span v-for="item in descricao.caracteristicas" :key="item.id">

                        <h4 class="font-weight-medium text-justify">

                          {{ item.designacao }}

                        </h4>

                      </span>

                    </td>

                  </tr>

                  <!-- <tr>

                          <td>Idade:</td>

                          <td class="font-weight-bold">30 anos</td>

                        </tr>

                        <tr>

                          <td>Email:</td>

                          <td class="font-weight-bold">joao.silva@example.com</td>

                        </tr> -->

                </tbody>

                <!--
</template>-->
              </v-simple-table>
              <!-- <span v-for="descricao in produto.caracteristicas" :key="descricao.id">
                <span class="text-uppercase">
                  {{ descricao.designacao }}:
                  <span v-for="item in descricao.caracteristicas" :key="item.id">
                    <h4 class="font-weight-bold">{{ item.designacao }}</h4>
                  </span>
                </span>
              </span> -->
            </v-tab-item>

            <v-tab-item>
              <!-- <div class="mb-8" v-for="classificacao in produto.classificacao" :key="classificacao.id">
                <div class="d-flex flex-wrap mb-4">
                  <v-avatar class="me-3" size="48">
                    <img src="@/assets/images/faces/default-avatar.png" alt="" />
                  </v-avatar>
                  <div>
                    <h5 class="font-600">***************</h5>
                    <div class="d-flex align-center justify-center mb-1">
                      <v-rating :value="parseFloat(classificacao.num_classificacao)" color="amber" class="me-2" dense half-increments readonly size="16"></v-rating>

                      <div class="font-600 me-2">
                        ({{ parseFloat(classificacao.num_classificacao) }})
                      </div>
                      <div class="grey--text text--darken-2">
                        {{ formateDate(classificacao.updated_at) }}
                      </div>

                    </div>
                  </div>
                </div>
                <v-row>
                  <v-col cols="12" sm="7" md="6" lg="4">
                    {{ classificacao.comentario }}
                  </v-col>
                </v-row>
              </div> -->

              <!-- <div class="mb-8">
                <div class="d-flex flex-wrap mb-4">
                  <v-avatar class="me-3" size="48">
                    <img src="@/assets/images/faces/6.png" alt="" />
                  </v-avatar>
                  <div>
                    <h5 class="font-600">Joe Kenan</h5>
                    <div class="d-flex align-center justify-center mb-1">
                      <v-rating
                        :value="4.5"
                        color="amber"
                        class="me-2"
                        dense
                        half-increments
                        readonly
                        size="16"
                      ></v-rating>

                      <div class="font-600 me-2">4.7</div>
                      <div class="grey--text text--darken-2">1.9 years ago</div>
                    </div>
                  </div>
                </div>

                <v-row>
                  <v-col cols="12" sm="7" md="6" lg="4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Varius massa id ut mattis. Facilisis vitae gravida egestas
                    ac account.
                  </v-col>
                </v-row>
              </div>
              <div class="mb-8">
                <div class="d-flex flex-wrap mb-4">
                  <v-avatar class="me-3" size="48">
                    <img src="@/assets/images/faces/8.png" alt="" />
                  </v-avatar>
                  <div>
                    <h5 class="font-600">Jenifer Tulio</h5>
                    <div class="d-flex align-center justify-center mb-1">
                      <v-rating
                        :value="4.5"
                        color="amber"
                        class="me-2"
                        dense
                        half-increments
                        readonly
                        size="16"
                      ></v-rating>

                      <div class="font-600 me-2">4.7</div>
                      <div class="grey--text text--darken-2">1.9 years ago</div>
                    </div>
                  </div>
                </div>
                <v-row>
                  <v-col cols="12" sm="7" md="6" lg="4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Varius massa id ut mattis. Facilisis vitae gravida egestas
                    ac account.
                  </v-col>
                </v-row>
              </div> -->
              <!-- <h2>

                            {{ produto.classificacao }}
                        </h2> -->
              <!-- <h2 class="font-600 mb-6">Write a Review for this product</h2>
            <h5 class="font-600 mb-3">
              Your Rating <sup class="primary--text">*</sup>
            </h5> -->
              <!-- <v-rating
              :value="0"
              color="amber"
              class="mb-3"
              background-color="amber"
              dense
              half-increments
              readonly
              size="20"
            ></v-rating>
            <h5 class="font-600 mb-3">
              Your Review <sup class="primary--text">*</sup>
            </h5>
            <v-textarea
              color="primary"
              class="mb-3"
              outlined
              background-color="white"
            >
            </v-textarea>
            <v-btn color="primary" class="text-capitalize"> Submit </v-btn> -->
            </v-tab-item>
          </v-tabs-items>

          <!-- <h3 class="mb-6">Produtos comprados juntos</h3>

          <v-row class="my-6">
            <v-col cols="12" sm="6" lg="2" md="4">
              <div class="d-flex align-center">
                <base-card>
                  <v-card-text>
                    <router-link
                      to="/single-product"
                      class="text-decoration-none"
                    >
                      <v-img
                        contain
                        class="card-overlay-item mb-2 br-8"
                        src="@/assets/images/products/Groceries/2.PremiumGroceryCollection.png"
                      ></v-img>
                      <h5 class="grey--text text--darken-4">Premium Grocery</h5>
                      <div class="d-flex">
                        <h5 class="primary--text me-2">$250</h5>
                        <h5
                          class="grey--text text--darken-1 text-decoration-line-through"
                        >
                          $250
                        </h5>
                      </div>
                    </router-link>
                  </v-card-text>
                </base-card>
                <div class="ms-1">
                  <h2 class="mb-0 grey--text text--darken-2">+</h2>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" lg="2" md="4">
              <div class="d-flex align-center">
                <base-card>
                  <v-card-text>
                    <router-link
                      to="/single-product"
                      class="text-decoration-none"
                    >
                      <v-img
                        contain
                        class="card-overlay-item mb-2 br-8"
                        src="@/assets/images/products/Groceries/3.PremiumGroceryPack.png"
                      ></v-img>
                      <h5 class="grey--text text--darken-4">
                        Premium Grocery Pack
                      </h5>
                      <div class="d-flex">
                        <h5 class="primary--text me-2">$250</h5>
                        <h5
                          class="grey--text text--darken-1 text-decoration-line-through"
                        >
                          $250
                        </h5>
                      </div>
                    </router-link>
                  </v-card-text>
                </base-card>
                <div class="ms-1">
                  <h2 class="mb-0 grey--text text--darken-2">+</h2>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" lg="2" md="4">
              <div class="d-flex align-center">
                <base-card>
                  <v-card-text>
                    <router-link
                      to="/single-product"
                      class="text-decoration-none"
                    >
                      <v-img
                        contain
                        class="card-overlay-item mb-2 br-8"
                        src="@/assets/images/products/Groceries/4.FreashRealCholeMasala.png"
                      ></v-img>
                      <h5 class="grey--text text--darken-4">Freash Masala</h5>
                      <div class="d-flex">
                        <h5 class="primary--text me-2">$250</h5>
                        <h5
                          class="grey--text text--darken-1 text-decoration-line-through"
                        >
                          $250
                        </h5>
                      </div>
                    </router-link>
                  </v-card-text>
                </base-card>
                <div class="ms-1">
                  <h2 class="mb-0 grey--text text--darken-2">+</h2>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" lg="2" md="4">
              <div class="d-flex align-center">
                <base-card>
                  <v-card-text>
                    <router-link
                      to="/single-product"
                      class="text-decoration-none"
                    >
                      <v-img
                        contain
                        class="card-overlay-item mb-2 br-8"
                        src="@/assets/images/products/Groceries/5.GumPack.png"
                      ></v-img>
                      <h5 class="grey--text text--darken-4">Gum Pack</h5>
                      <div class="d-flex">
                        <h5 class="primary--text me-2">$250</h5>
                        <h5
                          class="grey--text text--darken-1 text-decoration-line-through"
                        >
                          $250
                        </h5>
                      </div>
                    </router-link>
                  </v-card-text>
                </base-card>
                <div class="ms-1">
                  <h2 class="mb-0 grey--text text--darken-2">=</h2>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" lg="4" md="4">
              <base-card class="transparent h-100 d-flex align-center">
                <v-card-text class="d-flex flex-column align-center">
                  <h3 class="primary--text mb-2">$2500</h3>
                  <span class="text-14 grey--text text--darken-2 mb-6"
                    >Save $500</span
                  >
                  <div class="d-flex flex-wrap">
                    <v-btn
                      color="primary"
                      class="text-capitalize font-600 me-3 mb-2"
                    >
                      Adicionar ao carrinho
                    </v-btn>
                    <v-btn
                      color="primary"
                      outlined
                      class="text-capitalize font-600 mb-2"
                    >
                      Adicionar ao carrinho
                    </v-btn>
                  </div>
                </v-card-text>
              </base-card>
            </v-col>
          </v-row> -->

          <!-- ending:testing  -->

          <!-- <h3 class="mb-6">Outros vendedores</h3>
          <div class="d-flex align-center flex-wrap">
            <base-card class="text-center me-6 mb-6">
              <router-link to="/vendor-store" class="text-decoration-none">
                <v-card-text class="px-12 py-8">
                  <v-avatar size="48" class="mb-3">
                    <img src="@/assets/images/faces/propic.png" alt="" />
                  </v-avatar>
                  <h4 class="font-600 grey--text text--darken-3">
                    Tech Friend
                  </h4>
                </v-card-text>
              </router-link>
            </base-card>
            <base-card class="text-center me-6 mb-6">
              <router-link to="/vendor-store" class="text-decoration-none">
                <v-card-text class="px-12 py-8">
                  <v-avatar size="48" class="mb-3">
                    <img src="@/assets/images/faces/propic(1).png" alt="" />
                  </v-avatar>
                  <h4 class="font-600 grey--text text--darken-3">Smart Shop</h4>
                </v-card-text>
              </router-link>
            </base-card>
            <base-card class="text-center mb-6">
              <router-link to="/vendor-store" class="text-decoration-none">
                <v-card-text class="px-12 py-8">
                  <v-avatar size="48" class="mb-3">
                    <img src="@/assets/images/faces/propic.png" alt="" />
                  </v-avatar>
                  <h4 class="font-600 grey--text text--darken-3">Gadget 360</h4>
                </v-card-text>
              </router-link>
            </base-card>
          </div> -->
          <!-- v-if="produtosRelacionados && produtosRelacionados.length > 0" -->

          <!-- section-2  -->
          <!-- <div class="container mb-60">
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-space-between mb-4">
                  <div class="d-flex align-center">
                    <img v-svg-inline class="icon mr-2" src="@/assets/images/icons/light.svg" alt="">

                    <h2 class="font-weight-bold">
                      Flash Deals
                    </h2>
                  </div>
                  <a href="#" class="text-decoration-none blue-grey--text darken-1">
                    View all
                    <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
                  </a>
                </div>
              </v-col>
              <v-col cols="12" class="px-0">
                <div class="">
                  <VueSlickCarousel class="slick-secondary" :arrows="true" :dots="false" v-bind="slickSettings">
                    <v-col v-for="(item, index) in getProducts.slice(16, 24).map(n => n)" :key="index" cols="12">
                      <card-cart :content-img="item.img" :content-text="item.title" :counter="item.qty" class="m-5" @cartRemove="removeCart(item)" @cartAdd="addCart(item)" />
                    </v-col>

<template #prevArrow="arrowOption">
    <v-btn class="mx-2 slick-arrow prev slick-prev" fab dark small color="secondary">

        <v-icon dark>

            {{ arrowOption }} mdi-arrow-left

        </v-icon>

    </v-btn>
</template>
<template #nextArrow="arrowOption">
    <v-btn class="mx-2 slick-arrow next slick-next" fab dark small color="secondary">

        <v-icon dark>

            {{ arrowOption }} mdi-arrow-right

        </v-icon>

    </v-btn>
</template>
                  </VueSlickCarousel>
                </div>
              </v-col>
            </v-row>
          </div> -->

          <div v-if="produtosRelacionados.length > 0">
            <h3 class="mb-6">Produtos Relacionados</h3>
            <v-row v-if="loadingRelacionados">
              <v-col cols="12">
                <v-progress-linear indeterminate color="secondary"></v-progress-linear>
              </v-col>
            </v-row>

            <v-row v-if="!loadingRelacionados">
              <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="(item, index) in produtosRelacionados" :key="index">
                <v-card elevation="0" class="shadow-sm br-8 text-truncate" max-width="100%" :class="cardHoverShadow ? 'card-hover-shadow' : ''" @click="reloadProduto(item)">
                  <router-link class="custom-link" :to="{
                      name: 'ProdutoDetalhes',
                      params: { produto_id: item.uuid },
                    }">
                    <img height="250" :src="SERVER + item.imagem_produto" />
                  </router-link>

                  <v-card-text class="d-flex">
                    <div class="flex-grow-1 my-3">
                      <router-link class="custom-link" :to="{
                          name: 'ProdutoDetalhes',
                          params: { produto_id: item.id },
                        }">
                        <h6 class="text-uppercase text--darken-4">
                          {{ limitText(item.designacao) }}
                        </h6>
                        <h6 class="text-uppercase text--darken-4">
                          {{ remainingText }}
                        </h6>
                      </router-link>
                      <div class="d-flex mb-1">
                        <!-- {{ remainingText }} -->
                        <v-rating :value="
                            item.classificacao[0].classificacao == null ||
                            !item.classificacao[0].classificacao
                              ? 5
                              : parseInt(item.classificacao[0].classificacao)
                          " color="amber" background-color="amber" dense half-increments readonly size="17"></v-rating>
                        <!-- <div class="grey--text ml-1">
                          {{ item.classificacao[0].classificacao }}
                        </div> -->
                      </div>

                      <div class="d-flex">
                        <h6 class="primary--text mr-2 mb-0">
                          {{ formatePrice(item.pvp) }} AKZ
                        </h6>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>

                <!-- <CardCart
                  cardHoverShadow
                  :content-img="item.imagem_produto"
                  :content-text="item.designacao"
                  :counter="item.qty"
                  @cartRemove="removeCart(item)"
                  @cartAdd="addCart(item)"
                  :amount="item.pvp"
                  :produto="item"
                /> -->

              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <v-dialog name="dialog-produto-adicionado-carrinho" v-model="dialogProdutoAddedToCart" max-width="350" persistent>
        <v-card>
          <v-card-text class="text-center">
            <v-icon color="success" class="mt-2" size="70">mdi-check-circle</v-icon>
            <div class="mt-2">
              <span class="text-subtitle1 font-weight-bold">
                {{ message }}
              </span>
            </div>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn dense outlined @click="dialogProdutoAddedToCart = false" text>
              <!-- <v-icon>mdi-</v-icon>  -->
              Comprar Mais
            </v-btn>
            <!-- <br /> -->
            <v-btn color="green" class="white--text ml-2" :to="{ name: 'CarrinhoResumo' }" dense>
              Finalizar Compra
            </v-btn>
            <!-- <v-card-actions>
            <v-row>
              <v-col cols="12">
                <div class="justify-center mt-3">
                  <v-btn text @click="dialogProdutoAddedToCart = false">
                    Continuar Comprando
                  </v-btn>

                  <v-btn color="secondary" :to="{ name: 'CarrinhoResumo' }">
                    Finalizar Compra
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-actions> -->
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog name="dialog-produto-adicionado-favoritos" v-model="dialogAddedFavoritos" max-width="370">
        <v-card>
          <v-divider />

          <v-card-text>
            <div class="text-center">
              <v-icon color="secondary" size="60px">mdi-heart</v-icon>
            </div>
            <v-row class="text-left mt-2 text-center subtitle-1 primary--text">
              <span>
                <span class="font-weight-bold">{{ produto.designacao }} </span>
                adicionado aos favoritos com sucesso!
              </span>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>

    <v-dialog name="dialog-no-stock" v-model="dialogNoStock" max-width="300">
      <v-card>
        <v-card-text class="text-center">
          <v-icon color="error" class="mt-2" size="50">mdi-close</v-icon>
          <h6 class="mt-2">{{ message }}</h6>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog name="dialog-loading-dados" v-model="loading" max-width="300" persistent>
      <v-card>
        <v-card-text class="text-center">
          <v-progress-circular size="30" class="mt-6" indeterminate color="secondary" />
          <v-col cols="12">
            <span class="font-weight-medium"> Carregando dados...</span>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import {
    mapGetters,
    mapActions
} from "vuex";
import CardCart from "@/components/cartCard/CardCart";
import {
    LOGIN_URL,
    BASE_URL,
    SERVIDOR
} from "@/configs/api";
import axios from "axios";
import {
    barramento
} from "@/barramento";

export default {
    components: {
        CardCart,
        // shareIt,
    },
    computed: {
        ...mapGetters(["getProducts"]),
    },
    data() {
        return {
            remainingText: "",
            total: 0,
            last_page: 1,
            page: 1,
            slickSettings: {
                infinite: false,
                speed: 500,
                slidesToShow: 6,
                slidesToScroll: 6,
                initialSlide: 0,

                responsive: [{
                        breakpoint: 2100,
                        settings: {
                            slidesToShow: 6,
                            slidesToScroll: 6,
                            infinite: true,
                        },
                    },
                    {
                        breakpoint: 1900,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                            infinite: true,
                        },
                    },
                    {
                        breakpoint: 1800,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                            infinite: true,
                        },
                    },
                    {
                        breakpoint: 1264,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: true,
                        },
                    },
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            initialSlide: 2,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ],
            },
            cardHoverShadow: false,
            dialogNoStock: false,
            carrinho: {
                qty: 1,
            },
            loadingRelacionados: false,
            produtosRelacionados: [],
            dialogLoading: false,
            SERVER: SERVIDOR,
            item: {
                designacao: "",
            },
            items: [{
                    text: "Início",
                    disabled: false,
                    href: "/",
                },
                {
                    text: "Detalhes do Produto",
                    disabled: true,
                    href: "/",
                },
            ],
            token: "",
            cart: [],
            totalProducts: 0,
            totalPrice: 0,
            message: "",
            localProduto: {
                uuid: "",
            },
            dialogAddedFavoritos: false,
            user: {},
            loading: false,
            dialogProdutoAddedToCart: false,
            produto: {
                classificacao: {},
                imagem_produto: "",
                empresa: {
                    nome: "",
                },
            },
            tab: null,
            isQuantity: 0,
            flashDeals: [{
                    img: require("@/assets/images/products/flash-1.png"),
                    name: "Sneakers",
                },
                {
                    img: require("@/assets/images/products/flash-2.png"),
                    name: "Watch",
                },
                {
                    img: require("@/assets/images/products/flash-3.png"),
                    name: "Mobile",
                },
            ],
            singleProductData: {
                id: 1,
                img: require("@/assets/images/products/flash-1.png"),
                title: "Sneakers",
                category: "Sneakers",
                amount: 300,
                rating: 3,
                qty: 0,
            },
        };
    },
    mounted() {
        this.token = localStorage.getItem("TOKEN");
        this.getProdutoDetalhes();

        // setTimeout(() => {
        //     let uuid = this.produto.uuid;
        //     let page = 1
        //     axios
        //         .get(`${BASE_URL}/listarProdutos/relacionado/${uuid}?page=${page}`)
        //         .then((response) => {
        //             this.produtosRelacionados = response.data.data;
        //             this.last_page = response.data.last_page;
        //             this.total = response.data.total;
        //            setTimeout(() => {
        //                 this.loadingRelacionados = false;
        //             }, 50);
        //         })
        //         .catch(() => {
        //             this.loadingRelacionados = false
        //             this.loadingRelacionados = false;
        //             this.alert.type = "error";
        //             this.alert.text = "Não foi possivel conectar com o servidor!"; 
        //         });
        // }, 1500);

    },
    created() {

        const cart = localStorage.getItem("cart");
        const totalProducts = localStorage.getItem("totalProducts");
        const totalPrice = localStorage.getItem("totalPrice");

        // if (cart && totalProducts && totalPrice) {
        //   this.cart = JSON.parse(cart);
        //   this.totalProducts = Number(totalProducts);
        //   this.totalPrice = Number(totalPrice);
        // }
        // setTimeout(() => {
        //   this.getAllProdutosRelacionados();
        // }, 400);
    },
    methods: {
        addNoCarrinhoInput() {
            var TOKEN_SESSAO = localStorage.getItem("TOKEN");
            const url = `${BASE_URL}/carrinho/add/produto/${this.carrinho.qty}`;
            axios
                .post(
                    url, {
                        uuid: this.produto.uuid,
                    }, {
                        headers: {
                            Authorization: "Bearer " + TOKEN_SESSAO,
                        },
                    }
                )
                .then(() => {
                    this.message =
                        this.carrinho.qty +
                        " unidades de(o)" +
                        this.produto.designacao +
                        " adicionado ao carrinho com sucesso!";
                    this.dialogProdutoAddedToCart = true;
                    barramento.$emit("adicionarCarrinho");

                    setTimeout(() => {
                        this.loading = false;
                    }, 50);
                })
                .catch((error) => {
                    if (error.response.data.error) {
                        setTimeout(() => {
                            this.loading = false;
                        }, 300);
                        this.message = error.response.data.error;
                        this.dialogNoStock = true;
                    }
                });
        },
        checkQtyCart() {
            let produtoStock = this.produto.existencia_estock[0].quantidade;
            let carrinho = this.carrinho;
            if (carrinho.qty > produtoStock) {
                this.message = "Não existe quantidade suficiente deste produto.";
                this.dialogNoStock = true;
                this.carrinho.qty = 1;
            }
        },
        limitText(texto, limite = 28) {
            if (texto.length > limite) {
                // return texto.substring(0, limite) + "...";
                const truncatedText = texto.substring(0, limite);
                this.remainingText = texto.substring(limite);
                // return `${truncatedText}\n${remainingText}`;
                return truncatedText
            }
            return texto;
        },

        decrementar(produto) {
            if (this.carrinho.qty != 1) {
                this.carrinho.qty--;
            }
        },
        incrementar(produto) {
            let produtoStock = produto.existencia_estock[0].quantidade;
            let carrinho = this.carrinho;
            if (carrinho.qty < produtoStock) {
                this.carrinho.qty++;
            } else {
                this.carrinho.qty = 1;
            }
        },
        reloadProduto(item) {
            this.produto = item;
            // this.getAllProdutosRelacionados();
            let uuid = this.produto.uuid;
            let page = 1
            axios
                .get(`${BASE_URL}/listarProdutos/relacionado/${uuid}?page=${page}`)
                .then((response) => {
                    this.produtosRelacionados = response.data.data;
                    this.last_page = response.data.last_page;
                    this.total = response.data.total;
                })
                .catch(() => {
                    this.loadingRelacionados = false
                    this.loadingRelacionados = false;
                    this.alert.type = "error";
                    this.alert.text = "Não foi possivel conectar com o servidor!";
                });
        },

        getAllProdutosRelacionados(page = 1) {
            this.loadingRelacionados = true;
            let uuid = this.produto.uuid;
            axios
                .get(`${BASE_URL}/listarProdutos/relacionado/${uuid}?page=${page}`)
                .then((response) => {
                    this.produtosRelacionados = response.data.data;
                    this.last_page = response.data.last_page;
                    this.total = response.data.total;
                    // alert(JSON.stringify(response.data))
                    setTimeout(() => {
                        this.loadingRelacionados = false;
                    }, 50);
                })
                .catch(() => {
                    this.loadingRelacionados = false
                    this.loadingRelacionados = false;
                    this.alert.type = "error";
                    this.alert.text = "Não foi possivel conectar com o servidor!";
                    // this.dialogNoInternetAcess = true;
                });
        },
        formateDate(dataFinal) {
            const dataFormatada = moment(dataFinal).format("DD/MM/YYYY");
            return dataFormatada;
        },
        checkProdutoTemStock(produto) {
            return produto.existencia_estock[0].quantidade > 0;
        },
        addToCartStorage(product) {
            const existingProduct = this.cart.find((p) => p.uuid === product.uuid);
            if (existingProduct) {
                existingProduct.quantity++;
            } else {
                this.cart.push(product);
            }
            this.totalProducts++;
            this.totalPrice += product.pvp;
            this.updateCart();
            this.message =
                this.produto.designacao +
                " adicionado ao carrinho com sucesso! Os produtos são salvos temporariamente somente neste dispositivo. Queira por favor iniciar sessão pra guardar os produtos do carrinho na nuvem.";
            this.showDialogProdutoAddedToCart();
        },

        decreaseQuantity(product) {
            if (product.quantity > 1) {
                product.quantity--;
                this.totalProducts--;
                this.totalPrice -= product.pvp;
                this.updateCart();
            }
        },

        increaseQuantity(product) {
            product.quantity++;
            this.totalProducts++;

            this.totalPrice += product.pvp;
            this.updateCart();
        },

        removeFromStorageCart(product) {
            const index = this.cart.indexOf(product);
            if (index > -1) {
                this.cart.splice(index, 1);
                this.totalProducts -= product.quantity;
                this.totalPrice -= product.pvp * product.quantity;
                this.updateCart();
            }
        },
        updateCart() {
            sessionStorage.setItem("cart", JSON.stringify(this.cart));
            sessionStorage.setItem("totalProducts", this.totalProducts);
            sessionStorage.setItem("totalPrice", this.totalPrice);
        },
        ...mapActions(["addCart", "removeCart"]),
        showDialogProdutoAddedToCart() {
            this.dialogProdutoAddedToCart = true;
        },
        addProdutoNosFavoritos() {
            this.loading = true;
            var TOKEN_SESSAO = localStorage.getItem("TOKEN");
            if (!TOKEN_SESSAO) {
                setTimeout(() => {
                    this.loading = true;
                }, 4000);
                this.$router.push({
                    name: "PortalLogin",
                });
            } else {
                const url = `${LOGIN_URL}/empresa/checkFavorito`;
                axios
                    .post(
                        url, {
                            produto_id: this.produto.id,
                        }, {
                            headers: {
                                Authorization: "Bearer " + TOKEN_SESSAO,
                            },
                        }
                    )
                    .then((response) => {
                        this.dialogAddedFavoritos = true;
                        this.message = response.data.message;
                        setTimeout(() => {
                            this.loading = false;
                        }, 50);
                    })
                    .catch((error) => {
                        console.log(`error ${error}`);
                    });
            }
        },

        async addProdutoCarrinho() {
            this.loading = true;
            var TOKEN_SESSAO = localStorage.getItem("TOKEN");
            if (!TOKEN_SESSAO) {
                this.$router.push({
                    name: "PortalLogin",
                });
            } else {
                if (this.carrinho.qty > 1) {
                    this.addNoCarrinhoInput();
                } else {
                    const url = `${BASE_URL}/carrinho/add/produto`;
                    axios
                        .post(
                            url, {
                                uuid: this.produto.uuid,
                            }, {
                                headers: {
                                    Authorization: "Bearer " + TOKEN_SESSAO,
                                },
                            }
                        )
                        .then(() => {
                            this.message =
                                this.produto.designacao +
                                " adicionado ao carrinho com sucesso!";
                            this.dialogProdutoAddedToCart = true;
                            barramento.$emit("adicionarCarrinho");

                            setTimeout(() => {
                                this.loading = false;
                            }, 50);
                        })
                        .catch((error) => {

                            if (error.response.data.error) {
                                setTimeout(() => {
                                    this.loading = false;
                                }, 300);
                                this.message = error.response.data.error;
                                this.dialogNoStock = true;
                                if (error.response.status == 400) {
                                    console.log("Status de erro:", this.message);
                                }
                            }
                        });
                }
            }
        },
        formatePrice(value) {
            let val = (value / 1).toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        getProdutoDetalhes() {
            this.loading = true;
            axios
                .get(BASE_URL + "/produto/detalhes/" + this.$route.params.produto_id)
                .then((response) => {
                    this.produto = response.data;

                    setTimeout(() => {
                        this.loading = false;
                    }, 50);

                    // setTimeout(() => {
                    //     this.getAllProdutosRelacionados();
                    // }, 2000);

                })
                .catch((error) => {
                    if (error.response) {
                        this.loading = false;
                        this.alert.type = "error";
                    }
                });
        },
        getImagensProduto() {
            axios
                .get(BASE_URL + "/produto/detalhes/" + this.$route.params.produto_id)
                .then((response) => {
                    this.imagens = response.data;
                    this.loading = false
                });
        },
    },
};
</script>

<style>
.custom-link {
  text-decoration: none;
  /* Remove default text decoration */
  color: #000;
  /* Set the link color */
}

.custom-link:visited {
  /* Style for visited link (after it has been clicked) */
  text-decoration: none;
  /* Remove text decoration */
  color: #555;
  /* Set the color for visited links */
}
</style><style lang="scss" scoped>
.card-hover-shadow {
  cursor: pointer;
  transition: all 250ms ease-in-out 0s;

  &:hover {
    box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px !important;
    transition: all 250ms ease-in-out 0s;
  }
}

.product-slider {
  .product-slider-click {
    display: flex;
    justify-content: center;

    ::v-deep .slick-slide {
      width: 80px !important;
      margin-right: 16px;
      border: 1px solid rgb(218, 225, 231);
      border-radius: 5px;
    }

    ::v-deep .slick-current {
      border: 1px solid #d23f57;
      border-radius: 5px;
    }
  }
}
</style>
